import { Config } from '@grandvisionhq/shipping'

const config: Config = {
  inventoryChannels: {
    // Testing active-ants
    '8de577d3-1299-491c-bfab-1f6fc4075be9': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Testing picqer
    '185c0733-7d8a-41b5-9fbf-1a72e3b92906': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Acceptance active-ants
    '93084872-cd98-4a38-abb0-da20918ff26a': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Acceptance picqer
    '5a73b3ad-dcc1-43df-94a2-be04a67a632f': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Production active-ants
    '4e78aee3-e659-4dda-96de-58680eeb9b66': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
    // Production picqer
    '4516c1a4-19e8-4370-93cf-1d7523ed50a3': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '5',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '5',
  },
  nextDayDeliveryTimeLimit: '17:30',
  productTypeDeliveryConfig: [
    {
      type: 'MIXED',
      expectedDeliveryDays: '5+',
    },
    {
      type: 'SUNGLASSES',
      expectedDeliveryDays: '1',
    },
  ],
}

export const getDeliveryIndicatorConfig = () => config
